import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
// import VuePlyr from 'vue-plyr'
// import 'vue-plyr/dist/vue-plyr.css'

Vue.config.productionTip = false

import '@/permission.js' // permission control
// Vue.use(VuePlyr, {
//   plyr: {}
// })

Vue.use(ElementUI);

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

console.log=()=>{}
console.warn=()=>{}
console.info=()=>{}
// console.log=()=>{}