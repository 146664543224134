<template>
  <div class="sidebar-wrapper">
    <el-scrollbar wrap-class="scrollbar-wrapper">
      <el-menu
        default-active="2"
        background-color="#304156"
        text-color="#fff"
        active-text-color="#ffd04b"
        mode="vertical"
        :collapse="isCollapse"
        :router="true">

        <el-submenu index="0">
          <template #title>
            <i class="el-icon-data-line"></i>
            <span>监控</span>
          </template>
          <el-menu-item-group>
            <el-menu-item index="/index">在线用户折线图</el-menu-item>
            <el-menu-item index="/index/dataPersent">在线数据占比</el-menu-item>
          </el-menu-item-group>
        </el-submenu>

        <el-submenu index="1">
          <template #title>
            <i class="el-icon-collection"></i>
            <span>软件版本</span>
          </template>
          <el-menu-item-group>
            <el-menu-item index="/appversion">版本列表</el-menu-item>
            <el-menu-item index="/appversion/suggestions">用户问题反馈</el-menu-item>
          </el-menu-item-group>
        </el-submenu>

        <el-submenu index="2">
          <template #title>
            <i class="el-icon-document-checked"></i>
            <span>管理列表</span>
          </template>
          <el-menu-item-group>
            <el-menu-item index="/admin">管理列表</el-menu-item>
          </el-menu-item-group>
        </el-submenu>

        <el-submenu index="3">
          <template #title>
            <i class="el-icon-user"></i>
            <span>用户管理</span>
          </template>
          <el-menu-item-group>
            <el-menu-item index="/user">用户列表</el-menu-item>
          </el-menu-item-group>
        </el-submenu>

        <el-submenu index="4">
          <template #title>
            <i class="el-icon-video-play"></i>
            <span>音效管理V2</span>
          </template>
          <el-menu-item-group>
            <el-menu-item index="/sounds_v2">音效分类</el-menu-item>
            <el-menu-item index="/sounds_v2/add_menus">添加音效分类</el-menu-item>
            <el-menu-item index="/sounds_v2/table">音效列表</el-menu-item>
          </el-menu-item-group>
        </el-submenu>

        <el-submenu index="5">
          <template #title>
            <i class="el-icon-video-play"></i>
            <span>音效管理</span>
          </template>
          <el-menu-item-group>
            <el-menu-item index="/sounds">音效列表</el-menu-item>
            <el-menu-item index="/sounds/category">音效分类</el-menu-item>
            <el-menu-item index="/sounds/userSearchLog">搜索关键词分析</el-menu-item>
            <el-menu-item index="/sounds/findSound">找音效</el-menu-item>
          </el-menu-item-group>
        </el-submenu>

        <el-submenu index="6">
          <template #title>
            <i class="el-icon-suitcase"></i>
            <span>代理管理</span>
          </template>
          <el-menu-item-group>
            <el-menu-item index="/agents">代理列表</el-menu-item>
            <!-- <el-menu-item index="/sounds/category">音效分类</el-menu-item> -->
          </el-menu-item-group>
        </el-submenu>

        <el-submenu index="7">
          <template #title>
            <i class="el-icon-box"></i>
            <span>激活码类型管理</span>
          </template>
          <el-menu-item-group>
            <el-menu-item index="/codetypes">激活码类型列表</el-menu-item>
            <!-- <el-menu-item index="/sounds/category">音效分类</el-menu-item> -->
          </el-menu-item-group>
        </el-submenu>

        <el-submenu index="8">
          <template #title>
            <i class="el-icon-setting"></i>
            <span>软件设置</span>
          </template>
          <el-menu-item-group>
            <el-menu-item index="/app_setting">通用设置</el-menu-item>
          </el-menu-item-group>
        </el-submenu>
      </el-menu>
    </el-scrollbar>
  </div>
</template>

<script>

export default {
  name:'Sidebar',
  // components: { SidebarItem, Logo },
  data(){
    return {
      // isCollapse:false,
    }
  },
  props:{
    isCollapse:{
      type:Boolean,
      default:false
    }
  },
  methods:{

  },
  computed: {
    // activeMenu() {
    //   const route = this.$route
    //   const { meta, path } = route
    //   // if set path, the sidebar will highlight the path you set
    //   if (meta.activeMenu) {
    //     return meta.activeMenu
    //   }
    //   return path
    // },
    // showLogo() {
    //   return this.$store.state.settings.sidebarLogo
    // },
    // variables() {
    //   return variables
    // },
    // isCollapse() {
    //   return !this.sidebar.opened
    // }
  }
}
</script>

<style lang="scss">
.sidebar-wrapper{
    .scrollbar-wrapper {
      overflow-x: hidden !important;
    }

    .el-scrollbar__bar.is-vertical {
      right: 0px;
    }

    .el-scrollbar {
      height: 100vh;
    }

}
</style>