<template>
<!-- app内容包裹 -->
  <div class="app-wrapper">
      <!-- 左侧垂直导航 -->
      <div class="left-sidebar">
          <sidebar :isCollapse="isCollapse"/>
      </div>
      <!-- 主展示内容区域 -->
      <div class="main-container">
          <div class="head">
            <el-radio-group v-model="isCollapse" style="margin-right: 20px;" size="mini" @change="collapseChange">
                <el-radio-button :label="false">展开</el-radio-button>
                <el-radio-button :label="true">收起</el-radio-button>
            </el-radio-group>
            <el-dropdown @command="handleCommand">
            <span class="el-dropdown-link">
                {{user.username}}<i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
                <el-dropdown-item command="logout">退出登录</el-dropdown-item>
            </el-dropdown-menu>
            </el-dropdown>
          </div>
          <app-main />
      </div>
  </div>
</template>

<script>
import { AppMain,Sidebar } from './components'
export default {
    name: 'Layout',
    components: {
        AppMain,Sidebar
    },
    data(){
        return{
            isCollapse: true
        }
    },
    computed:{
        user(){
            return this.$store.getters.getUser
        }
    },
    methods:{
        collapseChange(v){

        },
        handleCommand(command) {
            switch(command){
                case 'logout':
                    this.$store.commit('logout');
                    this.$router.push({ path: '/login' })
                    console.log(command)
                break;
            }
        }
    },
    mounted(){
        console.log('store',this.$store.state)
    }
}
</script>

<style lang="scss">
.app-wrapper{
    position: relative;
    width: 100vw;
    height: 100vh;
    // background: yellow;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    overflow: auto;
    min-width: 1024px;
    .left-sidebar{
        -webkit-transition: width .28s;
        transition: width .28s;
        // max-width: 210px!important;
        // min-width: 60px;
        background-color: #304156;
        height: 100vh;
        position: relative;
        font-size: 0;
        // left: 0;
        // z-index: 1001;
        overflow: hidden;
        
    }
    .main-container{
        height: 100vh;
        transition: margin-left .28s;
        // margin-left: $sideBarWidth;
        position: relative;
        width: 80%;
        // background: brown;
        flex: 1;
        overflow: auto;
        .head{
            // background: yellow;
            height: 50px;
            transition: margin-left .28s;
            position: relative;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-end;
            padding: 0 20px;
        }
    }
}
</style>